.section-heading {
  display: flex;
  align-items: center;

  .title-number {
    color: var(--text-color-accent);
    font-size: clamp(1.2rem, 1.5vw, 1.52rem);
    font-weight: bold;
    font-family: "Roboto Mono", monospace;
    margin-right: 1rem;
  }

  .title-section {
    position: relative;
    top: -0.15rem;
    white-space: nowrap;
    font-size: clamp(1.5rem, 1.8vw, 2rem);
    color: var(--text-color-primary);
    font-weight: bold;

    &::before {
      content: "";
      position: absolute;
      top: -0.85rem;
      left: -1rem;
      width: 4rem;
      height: 2rem;
      background-color: var(--text-color-accent);
      opacity: 0.1;
      z-index: -1;
    }
  }

  .title-line {
    flex: 0.6;
    min-width: 5rem;
    height: 0.065rem;
    background-color: var(--background-color-light);
    transition: background-color 350ms ease-in-out;
  }
}

.section-heading.title-center {
  justify-content: center;
}
