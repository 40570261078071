#four-o-four {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .warning-text {
    font-family: "Roboto Mono", monospace;
    font-size: clamp(0.9rem, 1vw, 1.25rem);
    color: var(--text-color-secondary);
    text-align: center;
  }

  .home-button {
    font-family: "Roboto Mono", monospace;
    font-size: 0.985rem;
    padding-bottom: 0.85rem;
  }

  .four-o-four-animation {
    $colors: var(----text-color-primary), var(--text-color-secondary),
      var(--text-color-tertiary), var(--text-color-accent);
    $max: length($colors);
    $dash: 70;
    $dash-gap: 10;
    $dash-space: $dash * ($max - 1) + $dash-gap * $max;
    $time: 8s;
    $time-step: $time / $max;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-block-start: var(--navbar-height);
    flex-direction: column;
    flex: 1;
    cursor: default;

    text {
      font-weight: bold;
      letter-spacing: 0.9rem;
    }

    svg {
      width: 350px;
      height: 150px;
      opacity: 0.85;
    }

    .animated-text {
      fill: none;
      stroke-width: 7;
      stroke-linejoin: round;
      stroke-dasharray: $dash $dash-space;
      stroke-dashoffset: 0;
      -webkit-animation: stroke $time infinite linear;
      animation: stroke $time infinite linear;
      font-size: clamp(7.5rem, 8vw, 11rem);

      @for $item from 1 through $max {
        &:nth-child(#{$max}n + #{$item}) {
          $color: nth($colors, $item);
          stroke: $color;
          -webkit-animation-delay: -($time-step * $item);
          animation-delay: -($time-step * $item);
        }
      }
    }

    @-webkit-keyframes stroke {
      100% {
        stroke-dashoffset: -($dash + $dash-gap) * $max;
      }
    }

    @keyframes stroke {
      100% {
        stroke-dashoffset: -($dash + $dash-gap) * $max;
      }
    }
  }
}
