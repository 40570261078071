@use "../Styles/Breakpoints";

body[dark-mode] {
  #footer {
    &::before {
      background-image: url(../../assets/images/shapes.png);
    }
  }
}
body[light-mode] {
  #footer {
    &::before {
      background-image: url(../../assets/images/shapes-light.png);
    }
  }
}

#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 5rem;
  transition: background-color 350ms ease-in-out;
  position: relative;
  margin-top: auto;
  background-color: var(--background-color-dark);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 0 0;
    background-size: cover;
    opacity: 0.1;
  }

  .footer-sub-text {
    font-family: "Roboto Mono", monospace;
    color: var(--text-color-secondary);
    font-size: 0.865rem;
  }

  @include Breakpoints.desktop {
    flex-direction: row;
  }
  @include Breakpoints.tablet {
    flex-direction: row;
  }
  @include Breakpoints.phone {
    flex-direction: column-reverse;
    padding-bottom: 7.4rem;
  }

  .center-text {
    text-align: center;
  }

  .footer-social {
    @include Breakpoints.phone {
      text-align: center;
    }
    .social-links {
      display: flex;
      position: relative;
      flex: 1;
      gap: 1.225rem;

      .social-item {
        cursor: pointer;
        position: relative;
        transition: filter 0.15s ease-in-out, transform 0.15s ease-in-out;
        color: var(--text-color-primary);
        opacity: 0.95;

        &:hover {
          transform: translateY(-3px);
          color: var(--text-color-accent);
        }
        &:active {
          color: var(--text-color-tertiary);
          opacity: 0.8;
        }
      }
    }
  }

  .footer-credits {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include Breakpoints.phone {
      align-items: center;
      margin-bottom: 3.5rem;
    }

    .footer-name {
      font-family: "Roboto Mono", monospace;
      font-weight: bold;
      color: var(--text-color-primary);
      font-size: 1.25rem;
    }
    .git-stats {
      font-family: "Roboto Mono", monospace;
      display: flex;
      color: var(--text-color-secondary);
      font-size: 0.85rem;
      gap: 1rem;
    }
    .git-stat-icon {
      margin-top: 3px;
      margin-right: 3px;
      position: relative;
      top: 3px;
    }
  }
}
