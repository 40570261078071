@use "../Styles/Breakpoints";

.tab-layout-wrapper {
  min-height: 200px;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  @include Breakpoints.tablet {
    flex-direction: row;
  }
  @include Breakpoints.phone {
    flex-direction: column;
  }
}

.tab-fade-in {
  animation: tab-transition-fade-in 125ms ease-in-out forwards;
}

.tab-fade-out {
  animation: tab-transition-fade-out 125ms ease-in-out forwards;
}

@keyframes tab-transition-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tab-transition-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.tabs-list {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  @include Breakpoints.tablet {
    flex-direction: column;
  }
  @include Breakpoints.phone {
    overflow-x: auto;
    flex-direction: row;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;

    background: linear-gradient(
          90deg,
          var(--background-color) 20%,
          rgba(255, 255, 255, 0)
        )
        0 0,
      linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          var(--background-color) 80%
        )
        100% 100%,
      radial-gradient(
        farthest-side at 0% 50%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
          farthest-side at 100% 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        100%;

    background-repeat: no-repeat;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-attachment: local, local, scroll, scroll;
  }
}

.tab-item {
  text-decoration: none;
  color: var(--link-color-text);
  background-color: var(--link-color-primary);
  white-space: nowrap;
  padding: 10px 15px;
  border-radius: 12px;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  width: fit-content;
  cursor: pointer;

  @include Breakpoints.phone {
    border: 1px solid var(--background-color-light);
    border: var(--text-color-tertiary) 0.5px solid;
  }

  &:hover {
    color: var(--text-color-accent);
    background-color: var(--link-color-hover);
  }

  &:active {
    color: var(--text-color-tertiary);
  }
}

.selected-tab-item {
  color: var(--text-color-accent);
  background-color: var(--link-color-selected);
  box-shadow: #00000030 0px 0 0.15rem;
  transition: background-color 350ms;
}
