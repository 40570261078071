#experience {
  position: relative;
  opacity: 0;

  .experience-contents {
    display: flex;
    padding-top: 15rem;
    padding-bottom: 15rem;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: 0px auto;

    .experience-heading {
      margin-bottom: 1.75rem;
    }
    .company-name-wrapper {
      display: flex;
      align-items: flex-end;
    }
    .company-name {
      font-size: 1.4rem;
    }
    .company-designation {
      font-size: 1.2rem;
      color: var(--text-color-primary);
    }
    .company-duration {
      display: flex;
      align-items: center;
      font-family: "Roboto Mono", monospace;
      font-size: 0.95rem;
      color: var(--text-color-tertiary);
    }

    .dot-point {
      font-size: 1rem;
      color: var(--text-color-secondary);
    }
  }
}
