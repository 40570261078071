.theme-toggle-button {
  --button-dimension: 42px;
  --sun-animation-duration: 800ms;
  --core-animation-duration: 500ms;
  --sun-animation-curve: cubic-bezier(0.43, 0.06, 0, 1.21);
  --core-animation-curve: ease-in-out;

  position: relative;
  outline: none;
  border: none;
  border-radius: 12px;
  width: var(--button-dimension);
  height: var(--button-dimension);
  background-color: var(--background-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: background-color 350ms ease-in-out;
    background-color: var(--link-color-hover);
  }

  &:active {
    background-color: #8888885c;
  }

  .sun {
    border-radius: 50%;
    position: relative;
    width: 95%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform var(--sun-animation-duration)
      var(--sun-animation-curve);

    &::before {
      z-index: 1;
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      background-color: var(--text-color-primary);

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      z-index: 2;
      content: "";
      position: absolute;
      width: 50%;
      height: 50%;
      background-color: var(--text-color-primary);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .core {
    z-index: 3;
    position: absolute;
    width: 34%;
    height: 34%;
    border-radius: 50%;
    background-color: var(--background-color);

    &::before {
      z-index: 4;
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 68%;
      height: 68%;
      background-color: var(--text-color-primary);

      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform var(--core-animation-duration)
          var(--core-animation-curve),
        width var(--core-animation-duration) var(--core-animation-curve),
        height var(--core-animation-duration) var(--core-animation-curve);
      -webkit-transform: translate(-50%, -50%);
      -webkit-transition: -webkit-transform var(--core-animation-duration)
        var(--core-animation-curve);
    }
  }
}

body[dark-mode] {
  .theme-toggle-button {
    .sun {
      transform: rotate(0deg);
    }
    .core {
      &::before {
        transform: translate(-50%, -50%);
        width: 62%;
        height: 62%;
      }
    }
  }
}
body[light-mode] {
  .theme-toggle-button {
    .sun {
      transform: rotate(-225deg);
    }
    .core {
      &::before {
        transform: translate(-85%, -50%);
        width: 90%;
        height: 90%;
      }
    }
  }
}
