#main {
  position: relative;
  display: flex;
  justify-content: center;

  .main-contents {
    display: flex;
    min-height: max(752px, 100vh);
    max-width: 1300px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    opacity: 0;
    animation: initial-fade-in 0.75s ease-in-out forwards 0.5s;

    .hi {
      color: var(--text-color-accent);
      font-family: "Roboto Mono", monospace;
      font-size: 1.135rem;
    }
    .name {
      color: var(--text-color-primary);
      font-size: clamp(40px, 8vw, 80px);
      width: fit-content;
      font-weight: bold;
    }
    .designation {
      color: var(--text-color-secondary);
      font-size: clamp(32px, 6vw, 60px);
      font-weight: bold;
    }
    .description {
      margin-top: 20px;
      color: var(--text-color-secondary);
      font-size: clamp(16px, 3vw, 20px);
      max-width: 600px;
    }
    .get-in-touch-button {
      padding: 15px 25px !important;
      font-size: 0.9rem;
      display: inline-flex;
      align-self: flex-start;
      &::before {
        font-size: inherit;
        padding: 12px 25px !important;
      }
    }

    .glitch {
      position: relative;
      user-select: none;
    }
    @keyframes noise-anim {
      $steps: 25;
      @for $i from 0 through $steps {
        #{percentage($i * (1 / $steps))} {
          clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        }
      }
    }
    .glitch:after {
      content: attr(data-text);
      position: absolute;
      left: 2px;
      text-shadow: -1px 0 red;
      top: 0;
      color: white;
      overflow: hidden;
      clip: rect(0, 900px, 0, 0);
      animation: noise-anim 2s infinite linear alternate-reverse;
    }

    @keyframes noise-anim-2 {
      $steps: 10;
      @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
          clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        }
      }
    }

    .glitch:before {
      content: attr(data-text);
      position: absolute;
      left: -2px;
      text-shadow: 1px 0 red;
      top: 0;
      color: white;
      background: var(--background-color-primary);
      overflow: hidden;
      clip: rect(0, 900px, 0, 0);
      animation: noise-anim-2 3s infinite linear alternate-reverse;
    }
  }
}
