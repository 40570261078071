body[dark-mode] {
  --background-color: hsl(210, 100%, 12%);
  --background-color-dark: hsl(210, 91%, 9%);
  --background-color-light: hsl(210, 95%, 15%);
  --background-color-shapes: hsla(210, 100%, 17%, 0.5);

  --navbar-color: hsla(210, 100%, 12%, 0.75);
  --navbar-shadow: hsla(210, 100%, 5%, 0.65);

  --text-color-primary: hsl(209, 100%, 91%);
  --text-color-secondary: hsl(225, 31%, 75%);
  --text-color-tertiary: hsl(225, 17%, 53%);
  --text-color-accent: hsl(178, 100%, 51%);

  --button-color-primary: hsl(210, 100%, 18%);
  --button-color-secondary: hsl(210, 100%, 9%);
  --button-color-hover: hsl(210, 100%, 20%);
  --button-color-shadow: hsl(211, 100%, 8%);
  --button-color-text: var(--text-color-accent);

  --input-color-background: var(--background-color-dark);
  --input-color-shadow: hsla(178, 100%, 49%, 0.1);

  --link-color-primary: transparent;
  --link-color-text: var(--text-color-primary);
  --link-color-selected: hsl(210, 100%, 20%);
  --link-color-text-selected: var(--text-color-accent);
  --link-color-hover: hsl(210, 100%, 16.5%);

  --background-color-select: hsla(235, 19%, 37%, 0.527);
}

body[light-mode] {
  --background-color: hsl(0, 0%, 100%);
  --background-color-dark: hsl(201, 100%, 92%);
  --background-color-light: hsl(191, 100%, 98%);
  --background-color-shapes: hsla(198, 100%, 87%, 0.5);

  --navbar-color: hsla(0, 0%, 100%, 0.85);
  --navbar-shadow: hsla(210, 100%, 5%, 0.2);

  --text-color-primary: hsl(211, 60%, 19%);
  --text-color-secondary: hsl(210, 100%, 22%);
  --text-color-tertiary: rgb(136, 156, 207);
  --text-color-accent: hsl(210, 100%, 50%);

  --button-color-primary: var(--text-color-accent);
  --button-color-secondary: hsl(210, 100%, 30%);
  --button-color-hover: hsl(210, 100%, 55%);
  --button-color-shadow: hsla(210, 100%, 30%, 0.35);
  --button-color-text: hsl(0, 0%, 100%);

  --input-color-background: hsl(0, 0%, 96%);
  --input-color-shadow: hsl(218deg 100% 29% / 28%);

  --link-color-primary: transparent;
  --link-color-text: var(--text-color-primary);
  --link-color-selected: hsl(210, 100%, 96%);
  --link-color-text-selected: var(--text-color-accent);
  --link-color-hover: rgb(218, 237, 255);

  --background-color-select: hsla(216, 100%, 90%, 0.527);
}

body {
  --scrollbar-color: hsl(220, 21%, 36%);
  --navbar-height: 80px;
}
