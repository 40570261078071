@use "Breakpoints";

.button {
  width: auto;
  white-space: nowrap;
  position: relative;
  font-family: "Roboto Mono", monospace;
  background-color: var(--button-color-secondary);
  border: none;
  top: 2px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1;
  transition: background-color 350ms ease-in-out;

  &::before {
    content: attr(data-text);
    transition: top 80ms ease-in-out, background-color 350ms ease-in-out,
      box-shadow 80ms ease-in;
    color: var(--button-color-text);
    border-radius: 5px;
    font-family: inherit;
    font-size: inherit;
    padding: 7.5px 20px;
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--button-color-primary);
    z-index: 1;
  }

  &:hover {
    &::before {
      background-color: var(--button-color-hover);
      box-shadow: 0 0.4rem 0.5rem 0.1rem var(--button-color-shadow);
      top: -6px;
    }
  }
  &:active {
    &::before {
      top: -1px;
      box-shadow: none;
    }
  }
}

.hyperlink {
  color: var(--text-color-accent);
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.5px;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    background-color: var(--text-color-accent);
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:active {
    color: var(--text-color-tertiary);
  }

  &:active::after {
    background-color: var(--text-color-tertiary);
  }
}

.container {
  @include Breakpoints.desktop {
    padding-right: 150px;
    padding-left: 150px;
  }
  @include Breakpoints.tablet {
    padding-right: 100px;
    padding-left: 100px;
  }
  @include Breakpoints.phone {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.invisible {
  opacity: 0;
}

.fade-in-on-intersection {
  animation: initial-fade-in 0.5s ease-in-out forwards 0.2s;
}

@keyframes initial-fade-in {
  from {
    top: -15px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
