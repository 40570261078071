@use "../Styles/Breakpoints";

.background-shape {
  position: absolute;
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;

  * {
    transition: background-color 350ms ease-in-out, border 0.35s ease-in-out;
  }
  .cross-grid {
    width: 100px;
    display: grid;
    gap: 0.2rem;
    grid-template-columns: repeat(7, 1fr);
    position: relative;
    top: 2.5%;
    left: 6%;

    .cross {
      inset: 0;
      position: relative;
      transform: rotate(45deg);
    }

    svg {
      color: var(--text-color-accent);
      opacity: 0.08;
    }
  }

  .line {
    left: -10rem;
    width: calc(100vw + 15rem);
    height: 125px;
    display: block;
    background-color: var(--background-color-shapes);
    position: relative;
    opacity: 0.35;
  }

  .circle-line {
    position: relative;
    height: 350px;
    width: 750px;

    display: block;
    background-color: var(--background-color);
    border: 50px solid var(--background-color-shapes);
    border-radius: 250px;
    opacity: 0.35;
  }
}
