.dot-point {
  display: flex;
  margin-top: 0.45rem;
  align-items: center;

  svg {
    color: var(--text-color-accent);
    min-width: 1.2rem;
    margin-right: 0.3rem;
    align-self: flex-start;
    position: relative;
    top: 3.5px;
  }

  .text {
    flex-grow: 1;
  }
}
