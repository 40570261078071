@use "../Styles/Themes";
@use "../Styles/Reset";
@use "../Styles/Common";

html {
  min-height: 100%;
  position: relative;
  scroll-behavior: smooth;

  main {
    position: relative;
  }
}
