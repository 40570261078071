#skip-to-content {
  position: absolute;
  transform: translateX(calc(-100% - 00.65rem));
  top: calc(var(--navbar-height) + 0.8rem);
  background-color: var(--button-color-primary);
  color: var(--button-color-text);
  padding: 0.75rem 1.2rem;
  border-radius: 0 0.6rem 0.6rem 0;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Roboto Mono", monospace;
  text-decoration: none;
  font-size: 0.98rem;
  z-index: 100;
  box-shadow: 0 0 0.65rem -0.1rem var(--button-color-secondary);

  &:hover {
    background-color: var(--button-color-hover);
  }
  &:focus {
    transform: translateX(0);
  }
}
