@use "../Styles/Breakpoints";

.navbar-common {
  display: flex;
  z-index: 999;
  position: fixed;
  width: 100%;
  backdrop-filter: blur(10px);
  font-family: "Roboto Mono", monospace;
  align-items: center;
  left: 0;
  height: var(--navbar-height);
  background-color: var(--navbar-color);
  transition: background-color 350ms ease-in-out,
    box-shadow 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
    bottom 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.navbar {
  padding: 0 4rem;
  justify-content: space-between;

  @include Breakpoints.tablet {
    padding: 0 2.5rem;
  }

  @include Breakpoints.phone {
    padding: 0 1.5rem;
  }

  .nav-list-container {
    display: flex;
    align-items: center;
  }

  .nav-list {
    list-style: none;

    @include Breakpoints.phone {
      display: none;
    }

    li {
      display: inline;
      margin-left: 1em;
      font-size: 0.925rem;
    }

    .selected {
      background-color: var(--link-color-selected);
    }

    a {
      text-decoration: none;
      color: var(--link-color-text);
      background-color: var(--link-color-primary);
      padding: 10px 15px;
      border-radius: 12px;
      transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
      font-size: 0.925rem;

      &:hover {
        color: var(--text-color-accent);
        background-color: var(--link-color-hover);
      }

      &:active {
        color: var(--text-color-tertiary);
      }
    }
  }
}

.bottom-navbar {
  gap: 1rem;
  padding: 0 1.5rem;
  min-width: 384px;
  justify-content: space-around;
  box-shadow: 0 -10px 30px -10px var(--navbar-shadow);

  .selected {
    color: var(--text-color-accent);
    background-color: var(--link-color-selected);
    svg {
      color: var(--text-color-accent);
    }
  }

  & > div {
    flex: 1;
  }

  a {
    text-decoration: none;
    color: var(--link-color-text);
    background-color: var(--link-color-primary);
    padding: 10px 15px;
    font-size: 0.775rem;
    border-radius: 12px;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 1px;
    }

    &:hover {
      background-color: var(--link-color-hover);
    }

    &:active {
      color: var(--text-color-tertiary);
    }
  }
}

.nav-shadow {
  box-shadow: 0 10px 30px -10px var(--navbar-shadow);
}
.nav-visible {
  top: 0;
}
.nav-hidden {
  top: calc(-1 * (var(--navbar-height) + 10px));
}
.bottom-nav-visible {
  bottom: 0;
}
.bottom-nav-hidden {
  bottom: calc(-1 * (var(--navbar-height) + 10px));
}
.resume-button {
  margin: auto 0 auto 1.25rem;
  position: relative;
  padding: 12px 20px !important;
  font-size: 0.925rem;
  top: 0.5px;

  &::before {
    font-size: inherit;
    padding: 9px 20px !important;
  }
}
