@use "../../Styles/Breakpoints";

#about {
  position: relative;
  opacity: 0;

  .about-contents {
    display: flex;
    padding-top: 5rem;
    padding-bottom: 5rem;
    flex-direction: column;
    justify-content: center;
    max-width: 1500px;
    margin: 0px auto;

    .about-speaker {
      position: relative;
      top: 4px;
      cursor: pointer;
      &:hover {
        color: var(--text-color-accent);
      }
      &:active {
        opacity: 0.65;
      }
    }

    .about-row {
      display: flex;
      justify-content: space-between;

      @include Breakpoints.phone {
        flex-direction: column-reverse;
      }

      @include Breakpoints.tablet {
        flex-direction: column-reverse;
      }

      .about-text {
        max-width: 60%;
        color: var(--text-color-secondary);

        .about-name {
          color: var(--text-color-primary);
        }

        @include Breakpoints.phone {
          max-width: 100%;
        }
        @include Breakpoints.tablet {
          max-width: 100%;
        }
      }

      .about-image {
        &::before {
          content: "";
          position: absolute;
          display: flex;
          bottom: 0;
          left: 0;
          width: 300px;
          height: 300px;
          border-radius: 50%;
          background-color: var(--background-color-shapes);
          transition: background-color 350ms ease-in-out;
        }

        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 300px;
        height: 450px;
        position: relative;
        border-radius: 0 0 150px 150px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);

        img {
          position: relative;
          width: 100%;
          height: 100%;
          bottom: 0;
          object-fit: cover;
          transition: transform 0.5s ease, filter 0.5s ease;
          filter: grayscale(80%) contrast(1) brightness(90%);

          &:hover {
            transform: scale(1.2) translateY(-25px);
            filter: none;
          }
        }
      }

      .about-image-shadow {
        box-shadow: #00000070 0px 0.2rem 0.8rem;
        border-radius: 50%;
        width: 300px;
        height: 300px;
        position: absolute;
        bottom: 0;
      }

      .about-image-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        height: 450px;
        top: -105px;
        @include Breakpoints.phone {
          top: -65px;
        }
        @include Breakpoints.tablet {
          top: -65px;
        }
      }

      .about-recent-tech {
        display: flex;
        color: var(--text-color-secondary);
        font-family: "Roboto Mono", monospace;
      }
    }
  }
}
