@use "../../Styles/Breakpoints";

#contact {
  opacity: 0;
  position: relative;

  .contact-contents {
    display: flex;
    padding-top: 5rem;
    padding-bottom: 5rem;
    flex-direction: column;
    justify-content: center;
    max-width: 1500px;
    margin: 0px auto;

    .contact-description {
      max-width: 400px;
      text-align: center;
      line-height: 1.5;
      margin: 0px auto;
      font-family: "Roboto Mono", monospace;
      color: var(--text-color-secondary);
    }

    .contact-form-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto;
      width: 100%;
      max-width: 600px;
      min-height: 410px;

      form {
        width: 100%;
      }

      .contact-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8rem;
        grid-template-areas:
          "name email"
          "message message";

        @include Breakpoints.phone {
          grid-template-areas:
            "name name"
            "email email"
            "message message";
        }

        .name-wrapper {
          grid-area: name;
        }
        .email-wrapper {
          grid-area: email;
        }
        .message-wrapper {
          grid-area: message;
        }
      }

      .contact-form-status {
        font-family: "Roboto Mono", monospace;
        text-align: center;
      }

      .contact-form-submitting {
        svg {
          color: var(--text-color-accent);
          animation: fade-in 1s ease-in-out infinite;
        }
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
      }
      .contact-form-submitted {
        svg {
          color: var(--text-color-accent);
          opacity: 0;
          animation: fade-in-rotate 1s ease-in-out forwards;
        }
        @keyframes fade-in-rotate {
          to {
            opacity: 1;
            transform: rotateY(360deg);
          }
        }
      }

      .submit-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
