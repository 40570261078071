.diozz-input-wrapper {
  position: relative;

  .placeholder {
    color: var(--text-color-tertiary);
    position: absolute;
    font-size: 1rem;
    font-family: "Roboto Mono", monospace;
    width: 100%;
    height: 100%;
    top: 1.4rem;
    left: 1.25rem;
    pointer-events: none;
    transition: top 0.25s ease-in-out, font-size 0.25s ease;
  }

  .input-placeholder.suspend-placeholder {
    top: 9.5px;
    font-size: 0.8rem;
    color: var(--text-color-primary);
  }

  .textarea-placeholder.suspend-placeholder {
    top: 8.5px;
    font-size: 0.8rem;
    color: var(--text-color-primary);
  }

  .diozz-text-input {
    border: none;
    border-radius: 0.5rem;
    background-color: var(--input-color-background);
    transition: background-color 350ms ease-in-out;
    color: var(--text-color-primary);
    font-size: 1.1rem;
    padding: 1.5rem 1.2rem 1.2rem 1.2rem;
    outline: none;
    resize: none;
    font-family: "Roboto Mono", monospace;
    width: 100%;

    &:focus {
      box-shadow: 0 0 0.4rem var(--input-color-shadow);
    }
  }
}
