@use "Breakpoints";

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.422;

  @include Breakpoints.phone {
    -webkit-tap-highlight-color: transparent;
  }
  @include Breakpoints.tablet {
    -webkit-tap-highlight-color: transparent;
  }
}

::selection {
  background-color: var(--background-color-select);
}

#root {
  min-height: 100vh;
  min-width: 384px;
}

body {
  overflow: overlay;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color-primary);

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border: 3px solid var(--background-color);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
