@use "../../Styles/Breakpoints";

body[dark-mode] {
  #work {
    .work-item-image {
      img {
        mix-blend-mode: screen;
      }
    }
  }
}
body[light-mode] {
  #work {
    .work-item-image {
      img {
        mix-blend-mode: darken;
      }
    }
  }
}

#work {
  position: relative;
  opacity: 0;

  .work-contents {
    display: flex;
    padding-top: 5rem;
    padding-bottom: 5rem;
    flex-direction: column;
    justify-content: center;
    max-width: 1500px;
    margin: 0px auto;

    .flex-start {
      justify-content: flex-start;
    }

    .flex-end {
      justify-content: flex-end;
    }

    .work-item-wrapper {
      --notchSize: 35px;
      display: flex;
      filter: drop-shadow(0px 0px 8px #00000030);

      .work-item-back-shape {
        position: absolute;
        opacity: 0.1;
        z-index: -1;
      }

      .notch-left {
        clip-path: polygon(
          0% var(--notchSize),
          var(--notchSize) 0%,
          0 0,
          100% 0,
          100% calc(100% - var(--notchSize)),
          calc(100% - var(--notchSize)) 100%,
          0 100%,
          0% 0
        );

        .work-item-details {
          padding: 18px 22px 20px 32px;
        }

        @include Breakpoints.desktop {
          flex-direction: row;
        }
        @include Breakpoints.tablet {
          flex-direction: row;
        }
        @include Breakpoints.phone {
          flex-direction: column-reverse;
        }

        .work-item-image {
          left: 0;
        }

        .work-item-back-shape {
          left: -80px;
          bottom: -80px;
          transform: rotate(45deg);
        }
      }

      .notch-right {
        clip-path: polygon(
          0 0,
          0 0,
          calc(100% - var(--notchSize)) 0%,
          100% var(--notchSize),
          100% 100%,
          100% 100%,
          var(--notchSize) 100%,
          0% calc(100% - var(--notchSize))
        );

        .work-item-details {
          padding: 15px 25px 20px 25px;
        }

        @include Breakpoints.desktop {
          flex-direction: row-reverse;
        }
        @include Breakpoints.tablet {
          flex-direction: row-reverse;
        }
        @include Breakpoints.phone {
          flex-direction: column-reverse;
        }

        .work-item-image {
          &::after {
            right: 0;
          }
        }

        .work-item-back-shape {
          right: 0;
          bottom: 10px;
          transform: rotate(65deg);
        }
      }

      .work-item {
        display: flex;
        position: relative;
        width: 90%;
        overflow: hidden;
        background-color: var(--background-color-light);
        transition: background-color 350ms ease-in-out;

        @include Breakpoints.phone {
          width: 100%;
        }

        .work-item-details {
          flex: 1;

          .work-item-title-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .work-item-link {
              transition: color 0.25s ease;
              color: var(--text-color-secondary);

              &:hover {
                color: var(--text-color-accent);
              }

              &:active {
                color: var(--text-color-tertiary);
              }
            }
          }

          .work-item-description {
            color: var(--text-color-secondary);
            font-size: 1.0125rem;
            line-height: 1.2;
            margin-top: 0.5rem;
          }

          .work-item-title {
            font-size: clamp(1.5rem, 2.2vw, 2rem);
            color: var(--text-color-primary);
            font-weight: bold;
            transition: color 1s ease;
          }
        }

        .work-item-image {
          position: relative;
          overflow: hidden;
          flex: 0.8;
          min-height: 200px;
          img {
            transition: transform 0.5s ease;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            filter: grayscale(100%) contrast(1) brightness(90%);
          }

          &::after {
            content: "";
            transition: background-color 350ms ease-in-out;
            background-color: var(--background-color-shapes);
            position: absolute;
            width: 100%;
            height: 100%;
          }

          @include Breakpoints.tablet {
            z-index: -1;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            img {
              opacity: 0.09;
            }
            &::after {
              background-color: transparent;
            }
          }
        }

        &:hover {
          .work-item-title {
            color: var(--text-color-accent);
          }
          .work-item-image {
            img {
              transform: scale(1.1);
              mix-blend-mode: unset;
              filter: none;
            }

            &::after {
              background-color: transparent;
            }
          }
        }
      }
    }

    .see-all {
      display: flex;
      justify-content: center;
      margin: 4rem 0;
      svg {
        position: relative;
        top: 1px;
      }
    }
  }
}
