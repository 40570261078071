@use "../Styles/Breakpoints";

body[dark-mode] {
  .diozz-icon-wrapper {
    --color-fill: hsl(210, 91%, 9%);
    --color-outline: hsl(180, 100%, 33%);
    --color-gradient-outline: linear-gradient(
      90deg,
      hsl(180, 100%, 56%),
      hsl(180, 100%, 33%),
      hsl(180, 100%, 23%)
    );
  }
}
body[light-mode] {
  .diozz-icon-wrapper {
    --color-fill: hsl(203, 100%, 95%);
    --color-outline: hsl(210, 91%, 40%);
    --color-gradient-outline: linear-gradient(
      90deg,
      hsl(210, 91%, 55%),
      hsl(210, 91%, 40%),
      hsl(210, 91%, 25%)
    );
  }
}

.diozz-icon-wrapper {
  $height: 40px;
  $expandAnimDelay: 0.65;
  $initialDelay: 3;

  @include Breakpoints.phone {
    --icon-height: 35px;
    --icon-m-width: 47px;
    --icon-d-width: 43px;
  }
  @include Breakpoints.tablet {
    --icon-height: 40px;
    --icon-m-width: 55px;
    --icon-d-width: 48px;
  }
  @include Breakpoints.desktop {
    --icon-height: 40px;
    --icon-m-width: 55px;
    --icon-d-width: 48px;
  }
  @keyframes m-to-pos {
    from {
      z-index: 100;
    }
    to {
      left: -69%;
    }
  }

  @keyframes fade-out {
    to {
      display: none;
      opacity: 0;
    }
  }

  @keyframes shimmer-to-end {
    to {
      left: 100%;
    }
  }

  display: flex;
  height: var(--icon-height);
  justify-content: center;
  position: relative;
  gap: 0.5rem;

  .alpha,
  .d-static,
  .m-static {
    position: relative;
    background: var(--color-gradient-outline);
    height: var(--icon-height);
  }

  .d-static,
  .m-static {
    background: var(--color-gradient-outline);

    &::after,
    &::before {
      transition: background-color 350ms ease-in-out;
    }
  }

  .d-static {
    left: 6px;
    width: var(--icon-d-width);
    z-index: 1;
    clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%);
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 83%;
      top: 8.5%;
      width: 83%;
      left: 8.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 30%;
      height: 10%;
      bottom: 25%;
      left: 20%;
    }
  }

  .m-static {
    left: -6px;
    width: var(--icon-m-width);
    z-index: 2;
    clip-path: polygon(20% 0, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);

    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 83%;
      top: 8.5%;
      width: 83%;
      left: 8.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 25%;
      height: 18%;
      top: 8.5%;
      left: 50%;
      transform: translateX(-50%);
    }
    .left-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 11%;
      bottom: 8.5%;
      left: 25%;
    }
    .right-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 11%;
      bottom: 8.5%;
      right: 25%;
    }
  }

  .first-name {
    .non-cap {
      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          animation: fade-out
            #{(6 - $i) *
            ($expandAnimDelay / 6)}s
            ease-in
            forwards
            #{$initialDelay +
            $expandAnimDelay +
            0.05}s;
        }
      }
    }
    .alpha {
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          left: -2px * $i;
          z-index: 12 - $i;
        }
      }
    }
  }

  .last-name {
    .non-cap {
      @for $i from 1 through 7 {
        &:nth-child(#{$i}) {
          animation: fade-out
            #{(7 - $i) *
            ($expandAnimDelay / 6)}s
            ease-in
            forwards
            #{$initialDelay}s;
        }
      }
    }
    .alpha {
      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          left: -2px * $i;
          z-index: 6 - $i;
        }
      }
    }
  }

  .m {
    animation: m-to-pos #{$expandAnimDelay}s ease-in-out forwards #{$initialDelay +
      (($expandAnimDelay / 6) * 5) - 0}s;
  }

  .sm {
    height: calc(0.7 * var(--icon-height));
  }

  .first-name,
  .last-name {
    display: flex;
    align-items: flex-end;
  }

  .d {
    width: 50px;
    clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%);
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 83%;
      top: 8.5%;
      width: 83%;
      left: 8.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 30%;
      height: 10%;
      bottom: 25%;
      left: 20%;
    }
  }

  .i {
    width: 18px;

    &::before {
      content: "";
      position: absolute;
      background-color: var(--color-fill);
      height: 80%;
      top: 10%;
      width: 57%;
      left: 21.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 40%;
      height: 10%;
      top: 30%;
      left: 20%;
    }
  }

  .v {
    clip-path: polygon(0% 0%, 0% 75%, 25% 100%, 75% 100%, 100% 75%, 100% 0%);
    width: 32px;

    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 80%;
      top: 10%;
      width: 76%;
      left: 12%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 15%;
      left: 50%;
      height: 30%;
      top: 30%;
      transform: translateX(-50%);
    }
  }

  .n {
    width: 33px;
    clip-path: polygon(0 0, 75% 0, 100% 25%, 100% 100%, 0 100%);
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 80%;
      top: 10%;
      width: 76%;
      left: 12%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 30%;
      height: 20%;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .s {
    width: 33px;
    clip-path: polygon(
      20% 0,
      100% 0,
      100% 50%,
      90% 50%,
      90% 100%,
      90% 85%,
      80% 100%,
      0 100%,
      0 50%,
      10% 50%,
      10% 15%
    );
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 80%;
      top: 10%;
      width: 76%;
      left: 12%;
    }
    .left-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 10%;
      width: 20%;
      top: 50%;
      left: 12%;
    }
    .right-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 10%;
      width: 20%;
      bottom: 50%;
      right: 12%;
    }
  }

  .m {
    width: 55px;
    clip-path: polygon(20% 0, 80% 0, 100% 20%, 100% 100%, 0 100%, 0 20%);

    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 83%;
      top: 8.5%;
      width: 83%;
      left: 8.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 25%;
      height: 18%;
      top: 8.5%;
      left: 50%;
      transform: translateX(-50%);
    }
    .left-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 11%;
      bottom: 8.5%;
      left: 25%;
    }
    .right-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 11%;
      bottom: 8.5%;
      right: 25%;
    }
  }

  .a {
    width: 33px;
    clip-path: polygon(25% 0, 100% 0, 100% 25%, 100% 100%, 0 100%, 0 25%);
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 78%;
      top: 11%;
      width: 76%;
      left: 12%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 12%;
      height: 20%;
      bottom: 10%;
      right: 30%;
    }
  }

  .t {
    width: 24px;
    clip-path: polygon(
      0 0,
      75% 0,
      75% 30%,
      90% 30%,
      90% 45%,
      75% 45%,
      75% 65%,
      100% 65%,
      100% 100%,
      0 100%
    );

    &::before {
      content: "";
      position: absolute;
      background-color: var(--color-fill);
      height: 82%;
      top: 9%;
      width: 43%;
      left: 17%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-fill);
      height: 16%;
      width: 25%;
      bottom: 9%;
      right: 20%;
    }
    .notch {
      position: absolute;
      background-color: var(--color-outline);
      width: 50%;
      height: 15%;
      top: 30%;
      right: 10%;
    }
  }

  .h {
    width: 33px;
    clip-path: polygon(
      0 0,
      50% 0,
      50% 30%,
      77% 30%,
      100% 47%,
      100% 100%,
      0 100%
    );
    &::before {
      content: "";
      position: absolute;
      clip-path: polygon(
        0 0,
        35% 0,
        35% 40%,
        85% 40%,
        100% 53%,
        100% 100%,
        0 100%
      );
      background-color: var(--color-fill);
      height: 80%;
      top: 10%;
      width: 76%;
      left: 12%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 25%;
      height: 20%;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .e {
    width: 33px;
    clip-path: polygon(
      25% 0,
      75% 0,
      100% 25%,
      100% 100%,
      25% 100%,
      0 75%,
      0 25%
    );
    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 78%;
      top: 11%;
      width: 76%;
      left: 12%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 40%;
      height: 10%;
      bottom: 35%;
      right: 10%;
    }
  }

  .w {
    width: 38px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 20% 100%, 0 80%);

    &::before {
      content: "";
      position: absolute;
      clip-path: inherit;
      background-color: var(--color-fill);
      height: 83%;
      top: 8.5%;
      width: 83%;
      left: 8.5%;
    }
    &::after {
      content: "";
      position: absolute;
      background-color: var(--color-outline);
      width: 25%;
      height: 18%;
      bottom: 8.5%;
      left: 50%;
      transform: translateX(-50%);
    }
    .left-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 12%;
      top: 8.5%;
      left: 25%;
    }
    .right-wing {
      position: absolute;
      background-color: var(--color-outline);
      height: 15%;
      width: 12%;
      top: 8.5%;
      right: 25%;
    }
  }
}

.hover-shimmer {
  .d-static,
  .m-static {
    .shimmer {
      content: "";
      position: absolute;
      background-color: var(--text-color-accent);
      opacity: 0.4;
      height: 35%;
      width: 180%;
      top: 30%;
      left: -160%;
      z-index: 10;
      transform: rotate(-40deg);
    }
  }
  &:hover {
    .d-static {
      .shimmer {
        animation: shimmer-to-end 0.4s ease-out forwards 0.25s;
      }
    }
    .m-static {
      .shimmer {
        animation: shimmer-to-end 0.4s ease-in forwards 0.25s;
      }
    }
  }
}
