.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 0.6rem;

  .tag {
    background-color: var(--background-color-dark);
    padding: 0.25rem 0.75rem;
    border-radius: 0.5rem;
    color: var(--text-color-secondary);
    transition: color 0.2s ease-in-out, background-color 0.35s ease-in-out;
    font-size: 0.868rem;
    cursor: default;

    &:hover {
      color: var(--text-color-primary);
    }
  }
}
