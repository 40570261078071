@use "../Styles/Breakpoints";
@use "../Styles/Reset";

#root {
  display: flex;
  flex-direction: column;
}

#project-archive {
  padding-top: var(--navbar-height);
  border-collapse: separate;
  position: relative;
  max-width: 1600px;
  display: block;
  margin: 0 auto;

  .project-archive-heading .title-section {
    font-size: clamp(28px, 4.55vw, 40px);
  }

  .projects-table {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-spacing: 0px;

    @include Breakpoints.phone {
      .year-hide {
        display: none;
      }
    }

    th,
    td {
      text-align: start;
      padding: 0.8rem 1rem;
      cursor: default;
      border: none;
    }

    thead {
      font-size: 1.05rem;
      color: var(--text-color-primary);

      .tech-head {
        @include Breakpoints.phone {
          display: none;
        }
      }
    }

    tbody {
      font-size: 0.94rem;
      color: var(--text-color-secondary);

      .serial-number {
        font-family: "Roboto Mono", monospace;
        font-size: 0.85rem;
      }

      .tech-row {
        font-family: "Roboto Mono", monospace;
        font-size: 0.85rem;

        & > svg {
          position: relative;
          top: 4px;
        }
        & > span {
          position: relative;
          top: -4px;
        }
        @include Breakpoints.phone {
          display: none;
        }
      }

      .project-links {
        white-space: nowrap;
      }

      tr {
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

        .work-item-link {
          cursor: pointer;
          font-size: 1.3rem;
          position: relative;
          transition: top 0.1s ease-in-out;
          color: var(--text-color-primary);
          opacity: 0.95;
          top: 2px;

          path,
          svg {
            stroke: var(--text-color-primary);
          }

          &:hover {
            top: 0px;
            color: var(--text-color-accent);
            path,
            svg {
              stroke: var(--text-color-accent);
            }
          }
          &:active {
            color: var(--text-color-tertiary);
            opacity: 0.8;
            path,
            svg {
              stroke: var(--text-color-tertiary);
            }
          }
        }

        &:hover {
          border-radius: 10rem;
          background-color: var(--background-color-light);
          color: var(--text-color-primary);
        }
      }

      td {
        --row-border-radius: 6.5px;
        &:first-child {
          border-top-left-radius: var(--row-border-radius);
          border-bottom-left-radius: var(--row-border-radius);
        }
        &:last-child {
          border-top-right-radius: var(--row-border-radius);
          border-bottom-right-radius: var(--row-border-radius);
        }
      }
    }
  }
}
